import React, { useEffect } from 'react'
import { NextPage } from 'next'
import { AreaInfo, Background, Content, FormContainer } from 'src/components/Login/styles'
import Link from 'next/link'
import { FormProvider } from 'src/components/Common/Form'
import initialValues, { Values } from 'src/shapes/login'
import Field from 'src/components/Common/InputContainer'
import { Button } from 'src/components/Common/Button'
import Container from 'src/components/Common/Container'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Logo, LogoWrapper } from 'src/components/Header/styles'
import MenuSocialMedia from 'src/components/Common/SocialMendia'
import { useRouter } from 'next/router'
import isLogged from 'src/utils/isLogged'
import { apiPrivate } from 'src/service/api/api.private'
import { useErrors } from 'src/hooks/useErrors'
import { useDispatch } from 'react-redux'
import { setError } from 'src/store/errors'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { RecaptchaTerms } from 'src/components/Common/RecaptchaTerms/styles'

const Login: NextPage = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const dispatch = useDispatch()

  const { executeRecaptcha } = useGoogleReCaptcha()

  useErrors()

  useEffect(() => {
    if (isLogged()) {
      window.location.replace('/perfil')
    }
  }, [])

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (!executeRecaptcha) {
        // eslint-disable-next-line no-console
        console.log('Execute recaptcha not yet available')
        return
      }

      const recaptcha = await executeRecaptcha('SignIn')

      const res = await apiPrivate.login({
        payload: values,
        recaptcha,
      })

      if (res.kind === 'ok') {
        localStorage.setItem('authTokens', JSON.stringify(res.data))
        await router.replace('/perfil')
      } else {
        dispatch(
          setError({
            response: res,
            customMessages: {
              unauthorized: 'toast.loginError',
            },
          }),
        )
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validation.email.matches')).required(t('validation.required')),
      password: Yup.string().required(t('validation.required')),
    }),
  })

  return (
    <Background>
      <Container>
        <Content>
          <FormContainer>
            <h1>{t('login.form.title')}</h1>
            <div className="form-content">
              <FormProvider<Values> provider={formik}>
                <Field
                  required
                  name="email"
                  component="input"
                  preset="primary"
                  label={t('login.form.email')}
                  placeholder={t('login.form.placeholderEmail')}
                />
                <Field
                  required
                  type="password"
                  name="password"
                  component="input"
                  label={t('login.form.password')}
                  placeholder={t('login.form.placeholderPassword')}
                />
                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  preset="form"
                  labelTx={t('login.form.btn')}
                />
              </FormProvider>
            </div>

            <Link legacyBehavior passHref href="/recuperar-senha">
              <a>{t('login.resetPassword')}</a>
            </Link>

            <RecaptchaTerms>
              This site is protected by reCAPTCHA and the Google <br />
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </RecaptchaTerms>
          </FormContainer>

          <AreaInfo>
            <LogoWrapper>
              <a href="/">
                <Logo
                  src="https://res.cloudinary.com/cordasemusica/image/upload/v1678224794/logo_tk296u.gif"
                  alt="Logo Cordas e Música Projeto Musical"
                />
              </a>
            </LogoWrapper>

            <MenuSocialMedia />
            <p className="link">
              <Link legacyBehavior href="mailto:cordasemusica@gmail.com">
                contato@cordasemusica.com.br
              </Link>
            </p>
            <p className="contact-tel">{t('login.contactText')}</p>
            <p className="contact-tel">{t('login.tel')}</p>
          </AreaInfo>
        </Content>
      </Container>
    </Background>
  )
}

export default Login
