import styled from 'styled-components'

export const RecaptchaTerms = styled.small`
  &,
  a {
    color: #000 !important;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    margin-top: 16px;
  }
`
