import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
  background-image: url('https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/bg-login_o2rsli.png');
  background-attachment: fixed;
  background-size: 50px;
  background-position: right bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: #33333366;
    z-index: 2;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 3;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px #333;

  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  width: 95vw;
  max-width: 800px;

  @media ${device.mobileL} {
    width: 90vw;
  }

  @media ${device.tablet} {
    align-items: normal;
  }

  @media ${device.laptop} {
    width: 70vw;
  }

  @media ${device.laptopL} {
    max-width: 900px;
  }
`

export const FormContainer = styled.div`
  flex: 1;
  padding: 2rem 1rem 3.75rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.palette.white};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${device.tablet} {
    flex: 1.2;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  @media ${device.desktop} {
    flex: 1;
    padding: 2.5rem 2rem 3.75rem;
  }

  h1 {
    margin: 0 auto 1rem;
    ${({ theme }) => ({ ...theme.size.lg })};
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    @media ${device.laptopL} {
      padding-top: 1rem;
      ${({ theme }) => ({ ...theme.size.xl })}
    }
  }
  .form-content {
    width: 100%;
    padding: 1rem 1rem 2rem;

    button {
      width: 100%;
      margin-top: 0.6rem;
      text-transform: uppercase;
      div {
        width: 100%;
      }
      :hover {
        filter: brightness(90%);
      }
    }
  }

  a {
    ${({ theme }) => ({ ...theme.size.xs })};
    :hover {
      text-decoration: underline;
    }
  }
`

export const AreaInfo = styled.div`
  display: none;
  padding: 2rem 1rem;
  background: ${({ theme }) => theme.colors.palette.neutral300};

  #menu-social-media {
    margin-bottom: ${p => p.theme.spacing.medium};
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    flex: 1;
    border-radius: 0 0.5rem 0.5rem 0;
    border-left: 1px solid ${({ theme }) => theme.colors.palette.neutral400};
  }

  .link {
    a {
      margin-left: 0.2rem;
      ${({ theme }) => ({ ...theme.size.xs })}
      color: ${({ theme }) => theme.colors.palette.secondary600};
      @media ${device.laptopL} {
        ${({ theme }) => ({ ...theme.size.md })}
      }
    }
  }

  .contact-tel {
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    text-align: center;
    ${({ theme }) => ({ ...theme.size.xs })}
    @media ${device.laptopL} {
      ${({ theme }) => ({ ...theme.size.md })}
    }
  }
`
